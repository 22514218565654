import { defineStore } from 'pinia';
import { ref, computed, watch } from 'vue';
import { logEvent } from 'firebase/analytics';
import {
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
} from 'firebase/auth';
import { useBaseStore } from './BaseStore';
import { useSiteStore } from './SiteStore';

export const useAuthStore = defineStore('auth', () => {
    /* Stores */
    const storeBase = useBaseStore();
    const storeSite = useSiteStore();

    /* State */
    const user = ref(null);
    const loading = ref(false);
    const error = ref(null);

    const loggedIn = computed(() => {
        return user.value != null;
    });

    /* Log In / Out */
    function getEmailFromPw(password) {
        return password.substring(0, 4) === 'demo' ?
            'demo@yb-ww.ch' // ausnahmen wegen demo-user
            : storeSite.siteRoute === 'hochzeit-bsc-yb' && // ausnahmen wegen bsc-yb
            password.substring(0, 3) === '290'
            ? 'fotos@yb-ww.ch'
            : storeSite.siteRoute === 'hochzeit-bsc-yb' &&
              password.substring(0, 3) === '110'
            ? 'apero@yb-ww.ch'
            : storeSite.siteRoute === 'hochzeit-bsc-yb' &&
              password.substring(0, 3) === '011'
            ? 'abend@yb-ww.ch'
            : `${storeSite.siteRoute}${password.substring(0, 3)}@yb-ww.ch`;
    }

    function logIn(email, password, redirect) {
        console.log('logIn', email);
        loading.value = true;
        signInWithEmailAndPassword(getAuth(), email, password)
            .then(() => {
                error.value = null;
                logEvent(storeBase.analytics, 'login', { email: email });
                if (!_isSiteFromUser(storeSite.lastLoadedSiteRoute, email)) {
                    console.warn('logout due to wronge site');
                    logOut();
                } else if (redirect) redirect();
            })
            .catch((_error) => {
                error.value = _error.message;
                console.warn('Error while signing in:', _error.message);
                logEvent(storeBase.analytics, 'login-f', {
                    email: email,
                    error: _error.message,
                });
            })
            .finally(() => {
                loading.value = false;
            });
    }

    function logInAdmin() {
        signInWithPopup(getAuth(), new GoogleAuthProvider())
            .then((result) => {
                console.log('logged in');
                logEvent(storeBase.analytics, 'login', {
                    email: result?.user?.email,
                });
                storeBase.router.push('/');
            })
            .catch((error) => {
                const errorMessage = error.message;
                console.warn('error logging in:', errorMessage);
                logEvent(storeBase.analytics, 'login-f', {
                    email: error?.customData?.email,
                    error: errorMessage,
                });
            });
    }

    function logOut() {
        signOut(getAuth())
            .then(() => {
                console.log('logOut');
                error.value = null;
            })
            .catch((error) => {
                error.value = error.message;
                console.error('Error while signing out:', error.message);
            });
    }

    /* Auth Changed Listener */
    const _unsubscribeListenerAuthChanged = ref(null);

    function _createMainAuthChangedListener() {
        if (_unsubscribeListenerAuthChanged.value) return;
        _unsubscribeListenerAuthChanged.value = addAuthChangedListener(
            (_user) => {
                console.log('AuthStateChanged', _user?.email);
                user.value = _user;
            }
        );
    }

    function addAuthChangedListener(fx) {
        return onAuthStateChanged(getAuth(), fx);
    }

    _createMainAuthChangedListener();

    /* Auto LogOut */
    function _isSiteFromUser(site, email) {
        if (storeSite.isAdmin) return true;
        if (storeSite.isDemoUser) return true;
        if (site === 'example') return true;
        if (
            // ausnahme wegen bsc-yb
            site === 'hochzeit-bsc-yb' &&
            (email.startsWith('abend') ||
                email.startsWith('fotos') ||
                email.startsWith('apero'))
        )
            return true;
        return email.startsWith(site); // TODO: Prüfen mit @-Zeichen, falls z.B. 'hochzeit-x123@yb-ww.ch' und 'hochzeit-xyz456@yb-ww.ch'
    }

    function _onLastLoadedSiteRouteChanged() {
        const newSite = storeSite.lastLoadedSiteRoute;
        if (
            newSite != null &&
            newSite != undefined &&
            user.value?.email != undefined &&
            !storeSite.loading &&
            !_isSiteFromUser(newSite, user.value?.email)
        ) {
            console.warn('logout due to site change');
            logEvent(storeBase.analytics, 'logout_siteChange', {
                site: newSite,
                email: user.value?.email,
            });
            logOut();
        }
    }

    /* watchers */
    watch(() => storeSite.lastLoadedSiteRoute, _onLastLoadedSiteRouteChanged);
    watch(() => storeSite.loading, _onLastLoadedSiteRouteChanged);

    return {
        user,
        loading,
        error,
        loggedIn,
        logIn,
        logOut,
        addAuthChangedListener,
        getEmailFromPw,
        logInAdmin,
    };
});
